import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import { graphql } from "gatsby";
import Seo from '../../components/SEO/seo';
import Layout from '../../components/LayoutPlainText';
export const query = graphql`
  query($language: String!) {
    locales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;
export const _frontmatter = {
  "title": "Cookies",
  "path": "/nl/cookies"
};
const layoutProps = {
  query,
  _frontmatter
};
const MDXLayout = Layout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <h1>{`Cookie Beleid`}</h1>
    <p>{`Onze website (“Site”) gebruikt cookies om jou te onderscheiden van andere gebruikers op de site. Hierdoor zijn wij in staat om het gebruiksgemak van onze bezoekers te vergroten. Door het gebruik van onze site ga je akkoord met het gebruik van cookies.`}</p>
    <p>{`Een cookie is een klein tekstbestand dat we in je browser, computer of telefoon bewaren. Cookies onthouden wat je op onze site doet. Het kan dat sommige functionaliteit van onze website een cookie vereist. De rapportages die er mee kunnen maken zijn geanonimiseerd, we kunnen dus niets herleiden tot personen.`}</p>
    <h2>{`1. Soorten cookies`}</h2>
    <p>{`We gebruiken de volgende cookies:`}</p>
    <p><strong parentName="p">{`Functionele cookies`}</strong>{`
Geplaatst om de site naar behoren te laten functioneren, door bijvoorbeeld informatie die je invult te onthouden. Op die manier hoef je niet steeds gegevens opnieuw in te vullen. Of het uitlezen van je browserinstellingen om onze website optimaal te laten presteren.`}</p>
    <p><strong parentName="p">{`Analytisch cookies`}</strong>{`
Hiermee kunnen we het gebruik van de site meten, zodat we kunnen bepalen welke onderdelen het meest geschikt zijn voor onze bezoekers. We gebruiken de analyse software Google Analytics. We houden bijvoorbeeld bij hoeveel bezoekers er op onze site komen, op welke momenten van de dag en wat een bezoeker bekijkt.`}</p>
    <p>{`Sommige derde partijen zoals YouTube of advertentie platformen kunnen ook cookies gebruiken, maar daar hebben we geen controle over. Waarschijnlijk zijn dat ook functionele en analytische cookies. Het is mogelijk om alle cookies te blokkeren in je browserinstellingen, het kan zijn dat onze site dan niet goed presteert.`}</p>
    <h2>{`2. Cookies in de app`}</h2>
    <p>{`In onze app houden we ook statistieken bij over het gebruik. Dit zijn anonieme statistieken waarmee we rapportages kunnen maken. In de app hoef je hier geen expliciete toestemming voor te geven noch kun je je er voor afmelden. De statistieken zijn nooit te herleiden tot personen. Lees onze Privacy Verklaring om te zien hoe wij met dit soort gegevens omgaan.`}</p>



    <Seo title={props.pageContext.frontmatter.title} path={props.pageContext.frontmatter.path} mdxType="Seo" />

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      